
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, SOCKET_URL } from './const';
import { io } from 'socket.io-client';

const socket = io(SOCKET_URL);

function Admin() {
    const [cityArray, setCityArray] = useState([]);
    const [removes, setRemoves] = useState([]);

    const loadRemoves = () => {
        axios.get(`${API_URL}/removes`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setRemoves(res.data);
        }).catch((e) => {
            console.log(e);
        });
    }

    const loadAll = () => {
        axios.get(`${API_URL}/list-all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setCityArray(res.data);
        }).catch((e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        loadAll();
        loadRemoves();
    }, []);

    useEffect(() => {
        socket.on('cityTaken', (args) => {
            loadAll();
            loadRemoves();
        });
    });

    const handleDelete = (cityId) => {
        axios.post(`${API_URL}/remove/${cityId}`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            setCityArray(prevState => prevState.map(city => {
                if (city.id === cityId) {
                    return {
                        ...city,
                        name: null,
                        phone: null
                    };
                }
                return city;
            }));
            loadRemoves();
        }).catch(error => {
            // Handle any errors
            console.error('Error:', error);
        });

    };

    const handleRestore = (cityId) => {
        axios.post(`${API_URL}/restore/${cityId}`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res) => {
            loadAll();
            loadRemoves();
        }).catch(error => {
            // Handle any errors
            console.error('Error:', error);
        });
    }

    return (
        <div className='app'>
            <div className='container'>
                <div className='text-desc'>
                    <h1 className='title'>Kude si bee!</h1>
                </div>
                <div>
                    <div className="table">
                        {cityArray.map((city) => {
                            return (<div key={city.id} className="row">
                                <div className="cell city-name">{city.city}</div>
                                <div className="cell name-input">{city.name}</div>
                                <div className="cell name-input">{city.phone}</div>
                                <div className="cell button-delete"><button onClick={() => handleDelete(city.id)}>Obrisi</button></div>


                            </div>);
                        })}
                    </div>

                    <div>OBRiSANI</div>

                    <div className="table">
                        {removes.map((rem) => {
                            return (<div key={rem.id} className="row">
                                <div className="cell city-name">{rem.city.city}</div>
                                <div className="cell name-input">{rem.name}</div>
                                <div className="cell name-input">{rem.phone}</div>
                                <div className="cell button-submit"><button onClick={() => handleRestore(rem.id)}>Vrati</button></div>


                            </div>);
                        })}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Admin;
