
import './App.css';
import { useEffect, useState } from 'react';
import City from './models/City';
import logo from './logow.svg';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { API_URL, SOCKET_URL } from './const';
import { io } from 'socket.io-client';

const socket = io(SOCKET_URL);

function App() {

  const [cityArray, setCityArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  const hasTwoWordsWithThreeLetters = (str) => {
    const pattern = /\b[a-zA-ZčćžšđČĆŽŠĐ]{4,}\b.*\b[a-zA-ZčćžšđČĆŽŠĐ]{4,}\b/;
    return pattern.test(str);
  }

  useEffect(() => {
    socket.on('cityTaken', (args) => {
      setCityArray(prevState => prevState.map(city => {
        if (city.id === args['city']) {
          return {
            ...city,
            taken: true
          };
        }
        return city;
      }));
    });
  });

  const send_message = (cityId) => {
    const city = cityArray.find(city => city.id === cityId);
    if (!city) return;

    if (cookies.get('ccun')) {
      swal('Greška', 'Mozete se prijaviti samo jednom.', 'error');
      return;
    }
    if (!city.userName) {
      swal('Greška', 'Unesite ime i prezime', 'error');
      return;
    }
    if (!city.phoneNumber) {
      swal('Greška', 'Unesite broj telefona', 'error');
      return;
    }
    if (city.userName.length < 3 || city.phoneNumber.length < 6) {
      swal('Greška', 'Morate uneti puno ime i prezime i tacan broj telefona', 'error');
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/city/${city.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: city.userName,
        phone: city.phoneNumber,
      }),
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response as needed
        cookies.set('ccun', city.id, { path: '/' });
        socket.emit('takeCity', { cityId });
        setCityArray(prevState => prevState.map(city => {
          if (city.id === cityId) {
            return {
              ...city,
              taken: data.taken
            };
          }
          return city;
        }));
        swal('Čestitam', 'Uspešno ste se prijavili za klip', 'success');
      })
      .catch(error => {
        // Handle any errors
        console.error('Error:', error);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(`${API_URL}/city`)
      .then(response => response.json()) // Parse response as JSON
      .then(data => {
        const updatedCityArray = [...cityArray]; // Create a copy of the current cityArray
        data.forEach(d => {
          // Check if d.id already exists in updatedCityArray
          if (!updatedCityArray.some(city => city.id === d.id)) {
            updatedCityArray.push(new City(d));
          }
        });
        setCityArray(updatedCityArray); // Update the state with the new data
      })
      .catch(error => {
        // Handle any errors
        console.error('Error:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event, cityId) => {
    const { value } = event.target;

    setCityArray(prevState => prevState.map(city => {
      if (city.id === cityId) {
        return {
          ...city,
          userName: value
        };
      }
      return city;
    }));
  };

  const handlePhoneChange = (event, cityId) => {
    const { value } = event.target;

    setCityArray(prevState => prevState.map(city => {
      if (city.id === cityId) {
        return {
          ...city,
          phoneNumber: value
        };
      }
      return city;
    }));
  };

  return (
    <div className='app'>
      <div className='container'>
        <img src={logo} alt="logo" className='logo-img' />
        <div className='text-desc'>
          <h1 className='title'>Učestvujte u mom novom videu!</h1>
          <p>Ako živite u nekom od ovih gradova, možete se prijaviti da budete deo mog novog videa.</p>
          <p>Za svaki grad se može prijaviti samo jedna osoba, ako pored grada piše "POPUNJENO", neko se već prijavio.</p>
          <p>Nakon prijave, dobićete poruku na WhatsApp-u kako bih utvrdio da ste legitimni i nakon toga će vam stići pismo sa materijalom za video.</p>
          <p>Sve što treba je da snimite kratak video sa sadržajem paketa (ne mora vam se videti lice) i pošaljete na isti WhatsApp. Prijavite se samo ako živite ili često boravite u tom gradu.</p>
          <p>Hvala unapred što pratite moj rad i što ste odlucili da budete deo njega. Pozdrav od Alekse!</p>
        </div>
        <div>
          <div className="table">
            {cityArray.map((city) => {
              return (<div key={city.id} className="row">
                <div className="cell city-name">{city.name}</div>
                {city.taken ? (<>
                  <div className="cell disabled-input"><input type='text' placeholder='POPUNJENO' value="POPUNJENO" disabled /></div>
                </>) : (<>
                  <div className="cell name-input"><input type='text' value={city.userName || ''}
                    onChange={(event) => handleInputChange(event, city.id)} placeholder='Ime i prezime' /></div>
                  <div className="cell name-input"><input type='text' value={city.phoneNumber || ''}
                    onChange={(event) => handlePhoneChange(event, city.id)} placeholder='Broj telefona' /></div>
                  <div className="cell button-submit"><button onClick={() => send_message(city.id)}>Pošalji</button></div>
                </>)}
                {parseInt(cookies.get('ccun')) === city.id ? (<div className="cell disabled-input"><input type='text' placeholder='Hvala sto ste se prijavili!' value="Hvala sto ste se prijavili!" disabled /></div>) : ''}
              </div>);
            })}
          </div>
          <br /><br /><br /><br />

        </div>
      </div>
    </div>
  );
}

export default App;
