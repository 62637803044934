import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import Admin from './Admin';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const lockedRoutes = () => {
  if (localStorage.getItem("token")) {
    return [
      {
        path: "panel",
        element: <Admin />
      },
    ];
  }
  return [];
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/admin",
    children: [
      {
        path: "",
        element: <Login />
      },
      ...lockedRoutes()
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);