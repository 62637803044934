
import './App.css';
import { useEffect, useRef } from 'react';
import logo from './logow.svg';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from './const';

function Login() {
  const password = useRef(null);

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    axios.post(`${API_URL}/admin/login`, {
      password: password.current.value
    }).then((res) => {
      console.log(res);
      localStorage.setItem("token", res.data.authorisation.token);
      navigate('/admin/panel')
    }).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {

  }, []);

  return (
    <div className='app'>
      <div className='container'>
        <img src={logo} alt="logo" className='logo-img' />
        <div>
          <form onSubmit={handleLogin}>
            <input type='password' ref={password} />
            <input type='submit' />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
